import { MainSection, Transitions } from "./pages";
import { Helmet } from "react-helmet-async";

function App() {
  return (
    <Transitions>
      <Helmet>
        <title>Majal | Inicio</title>
        <meta
          name="description"
          content="Página de inicio de la banda Uruguaya Majal, conocé más sobre nosotros mientras escuchás nuestros temas"
        />
        <link rel="canonical" href="/" />
      </Helmet>
      <MainSection />
    </Transitions>
  );
}

export default App;
