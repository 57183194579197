import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HomeModal } from "../../components";
import { shuffleArray } from "../../utils";

import HeroSlider, { Slide } from "hero-slider";

import { MajalStore, MajalImage } from "../../types/types";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MainSection = () => {
  const [isAudioActive, setIsAudioActive] = useState(true);
  const [isVideoVisible, setIsVideoVisible] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showModal, setShowModal] = useState(false);
  const [randomGigs, setRandomGigs] = useState([]);

  // @ts-ignore
  const { gigs } = useSelector((state: MajalStore) => state?.mediaForMajal);

  const showToast = () =>
    toast.warning("Escucha EPitafio, ya disponible!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      icon: "🔥",
      toastId: "majal-epitafio-preview",
    });

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    if (windowWidth < 1024) {
      setIsVideoVisible(false);
    } else {
      setIsVideoVisible(true);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (gigs?.length > 0) {
      const rdmGigs = shuffleArray(gigs);
      setRandomGigs(rdmGigs);
    }
  }, [gigs]);

  useEffect(() => {
    // setShowModal(true);
    showToast();
  }, []);

  return (
    <>
      {isVideoVisible ? (
        <video autoPlay loop muted={isAudioActive} className="hero-video">
          <source
            src="./assets/images/ccb39273-c219-4486-a72a-3e69fb0d3213.mp4"
            type="video/mp4"
          />
        </video>
      ) : (
        <HeroSlider
          height={"100vh"}
          autoplay
          controller={{
            initialSlide: 1,
            slidingDuration: 500,
            slidingDelay: 100,
          }}
          animations={{ slidingAnimation: "fade" }}
          className="hero-background"
        >
          {randomGigs?.map((image: MajalImage, index) => (
            <Slide
              key={index}
              background={{
                backgroundImageSrc: image.secure_url,
              }}
            />
          ))}
        </HeroSlider>
      )}
      <div className="mjl-container-home">
        <header className="mjl-container-home--header">
          <img
            src="/majal-tentacles-transparent-white.png"
            alt="Majal Tentáculos"
          />
          <h1 className="majal-title">MAJAL</h1>
        </header>
        <footer className="mjl-container-home--footer">
          <div className="social-media">
            <div className="icon-box">
              <a
                href="https://www.instagram.com/majal_banda/"
                target={"_blank"}
                rel="noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
            <div className="icon-box">
              <a
                href="https://twitter.com/majalbanda"
                target={"_blank"}
                rel="noreferrer"
              >
                <i className="fa-brands fa-x-twitter"></i>
              </a>
            </div>
            <div className="icon-box">
              <a
                href="mailto:banda.majal.rock@gmail.com"
                target={"_blank"}
                rel="noreferrer"
              >
                <i className="far fa-envelope"></i>
              </a>
            </div>
            <div className="icon-box">
              <a
                href="https://open.spotify.com/artist/1AEigCi2awIw60QIdrMcjZ"
                target={"_blank"}
                rel="noreferrer"
              >
                <i className="fab fa-spotify"></i>
              </a>
            </div>
            <div className="icon-box">
              <a
                href="https://www.youtube.com/@MajalBanda"
                target={"_blank"}
                rel="noreferrer"
              >
                <i className="fab fa-youtube"></i>
              </a>
            </div>
            <div className="icon-box">
              <a
                href="https://music.apple.com/us/artist/majal/1683598625"
                target={"_blank"}
                rel="noreferrer"
              >
                <i className="fab fa-apple"></i>
              </a>
            </div>
          </div>
        </footer>
      </div>
      {isVideoVisible ? (
        <div
          className="video-volume"
          onClick={() => setIsAudioActive(!isAudioActive)}
        >
          {isAudioActive ? (
            <i className="fas fa-volume-up"></i>
          ) : (
            <i className="fas fa-volume-mute"></i>
          )}
        </div>
      ) : null}
      <div className="mjl-tostify-container">
        <div onClick={() => setShowModal(true)}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </div>
      </div>
      <HomeModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default MainSection;
