import { MediaForMajalType } from "../types/types";

export const updateMajalMedia = (payload: MediaForMajalType | {}) => {
  return {
    type: "UPDATE_MAJAL_MEDIA",
    payload: payload,
  };
};

export const updateModalImage = (payload: string) => {
  return {
    type: "UPDATE_MODAL_IMAGE",
    payload: payload,
  };
};
