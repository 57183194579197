import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Transitions from "../Transitions";

const MEMBERS = [
  {
    name: "LUISINA CHAVARRÍA",
    instrument: "Bajo y coros",
    description:
      "Inicio mi camino musical con amigos, en Cenn Boirne, banda de Folklore en San José de Mayo, como guitarrista. Posteriormente integré otros proyectos en los que participo hasta la actualidad: Tía Nilda (banda de pop-rock) y Majal (banda de rock-punk), en esta última como bajista.<br />Recientemente integrada a Hija Única (banda experimental, rock alternativo).",
    imgUrl: "./assets/images/about-us/majal-luisina-chavarria.jpg",
    instaUrl: "https://www.instagram.com/lu_chava26/",
    xUrl: "https://twitter.com/majalbanda",
  },
  {
    name: "SANTIAGO BENTANCOR",
    instrument: "Cantante",
    description:
      "Mi nombre es Santiago Bentancor nací el 10 de Mayo de 1995, comencé a interesarme en la música desde muy pequeño, mis primeros pasos fueron a los 8 años cuando ingresé al coro de niños de la casa de la cultura en mi ciudad natal, en la adolescencia integré la murga de mi liceo “La Reprobada” y amé el género desde ese momento, integrando posteriormente otros, tanto de murgas como humoristas en los cuales además de cantar, imitaba y actuaba. Aproximadamente a los 21 años compré mi primer guitarra, y al año de eso comencé a subir videos tocando y cantando. Desde 2018 hasta la actualidad soy integrante de una banda de rock-pop “Tía Nilda” y a fines del 2022 surge este proyecto maravilloso que es Majal, del cual estoy feliz de formar parte.<br />Cuando miro hacia atrás y reflexiono todo lo que he vivido gracias a la música me doy cuenta de que con ella  todo se torna mucho más mágico, permitiéndome bajar a tierra o por el contrario elevarme de una forma inexplicable. Los momentos más hermosos y también más tristes siempre fueron acompañados de ella. Se que la frase “la música me salvo” es super trillada, pero doy fe que en mi caso fue la herramienta de escape y de sanación más grande que he encontrado. Cantar, componer, subirme arriba de los escenarios es lo que me hace sentir vivo, y es lo que no quiero perder nunca y lo que quiero seguir haciendo por el resto de mi vida.",
    imgUrl: "./assets/images/about-us/majal-santiago-bentancor.jpg",
    instaUrl: "https://www.instagram.com/santibenta/",
    xUrl: "https://twitter.com/santibenta",
  },
  {
    name: "DARWING BRITOS",
    instrument: "Batería",
    description:
      "Darwing Britos baterista desde niño me intereso la música después de escuchar música como de Spinetta o Pink Floyd.<br />A Los 12 fui dos años a guitarra y ya luego comencé con la batería tomando clases con:<ul><li>Emiliano Saavedra (baterista de Gustavo Cordera)</li><li>Escuela de Música salados</li><li>José Pablo Casulo (baterista banda municipal Canelones).</li></ul>Carrera de músico en EMM (Escuela de Música Montevideo).<br />Piano: Cristina Santos Varela.<br />Estuve en diferentes bandas como: Atajo , Amalgama, Lado G, Tía Nilda, Sociedad Anónima (carnaval oficial) y actualmente majal.<br />Tengo como influencia musical a: Spinetta Fito Paez, Pear Jam, entre otras.",
    imgUrl: "./assets/images/about-us/majal-darwing-britos.jpg",
    instaUrl: "https://www.instagram.com/darwing.britos/",
    xUrl: "https://twitter.com/majalbanda",
  },
  {
    name: "JOAQUÍN FIERRO",
    instrument: "Guitarra principal",
    description:
      "Hola! Mi nombre es Joaquín Fierro y nací el 21 de marzo del 2002 en la bella ciudad de Montevideo.<br />A los tres años recuerdo que mis padres me regalaron una batería de plástico la cual destruí a los pocos días por lo fuerte que la tocaba. Luego de eso pase a tocar el tambor en un grupo de candombe del departamento de Treinta y Tres.<br />A los 12 años empecé a tocar la guitarra y fue descubrir un mundo nuevo, podía estar horas con la guitarra y era el niño más feliz del mundo. Durante el proceso de aprendizaje fui descubriendo bandas que fueron marcando mi estilo de tocar y mi estilo de crear música.<br />Actualmente mis grandes inspiraciones para mi lado como solista son: Niña Lobo, Usted Señalemelo, Conociendo Rusia, El Mató a un Policía Motorizado",
    imgUrl: "./assets/images/about-us/majal-joaquin-fierro.jpg",
    instaUrl: "https://www.instagram.com/joaquin_fierro_/",
    xUrl: "https://twitter.com/joaquin_fierro",
  },
  {
    name: "NICOLÁS MUJICA",
    instrument: "Guitarra rítmica",
    description:
      "Mi nombre es Nicolás Mujica, nací el 22 de marzo de 2000 y soy fanático de Racing. Toco la guitarra desde los 9 años, siempre estuve interesado en la música, crecí escuchando Cuarteto de Nos y AC/DC, no tengo recuerdos de mi vida sin música. Mis primeros años con la guitarra fue en una banda de covers con otros compañeros que aprendían otros instrumentos en el barrio. <br /> Hace 5 años que integro Fuimos Nosotros, banda de rock que llevo con mis amigos del liceo, y ahora tengo una hermosa oportunidad de estar acá en Majal con más amigos. Siento que la música es una de las formas de expresión más hermosas que tenemos los seres vivos y quiero poder compartir lo que siento a través de mi guitarra (y a veces voz). Además de guitarra, toco piano, bajo y batería (y si me dejan un rato toco el acordeón). Esto me ha dado una vista musical muy amplia, no tengo un género favorito, pero mis preferidos son Rock (con exponentes como AC/DC, Buitres y Led Zeppelin), Pop (El Cuarteto, Miranda! y MJ) y el Punk (Trotsky Vengaran, Ramones y Green Day). <br />Mi filosofía musical es poder disfrutar la música con amigos y a puro rock!.",
    imgUrl: "./assets/images/about-us/majal-nicolas-mujica.jpg",
    instaUrl: "https://www.instagram.com/nicolasmujica6/",
    xUrl: "https://twitter.com/NicoMujicaa",
  },
  {
    name: "JHONNIER ZAPATA",
    instrument: "Guitarra rítmica (hasta 10/2023)",
    description:
      "Jhonnier Zapata nació el 30 de enero de 1997 en la ciudad de Pereira, Colombia.<br />Desde temprana edad, su pasión por la música despertó, siendo influenciado principalmente por sus familiares cercanos, quienes tradicionalmente tocaban la guitarra en reuniones y eventos caseros debido a la falta de equipos de audio en aquellos años. <br />Inicialmente, Jhonnier comenzó a explorar la guitarra de manera autodidacta, dedicando años a perfeccionar su técnica. Sin embargo, hace aproximadamente un año, tuvo la oportunidad de recibir clases más formales del profesor Agustín Cristófalo en Montevideo, lo que le ha permitido seguir creciendo y desarrollándose como guitarrista. Actualmente, forma parte de Majal, donde continúa aprendiendo y compartiendo su pasión por la música.<br />A lo largo de su vida, Jhonnier ha tenido la experiencia de vivir en Argentina y Uruguay, lo cual ha influido considerablemente en sus gustos musicales. Bandas como Ekhymosis, Aterciopelados y Doctor Krápula han dejado una fuerte impresión en su estilo y han moldeado sus influencias.",
    imgUrl: "./assets/images/about-us/majal-jhonnier-zapata.jpg",
    instaUrl: "https://www.instagram.com/jhonnierandrey/",
    xUrl: "https://twitter.com/JhonnierAndrey",
  },
];

const AboutUs = () => {
  useEffect(() => {
    const footer = document.querySelector("#footer");
    footer?.classList.remove("footer-fixed");

    return function cleanup() {
      const footer = document.querySelector("#footer");
      footer?.classList.add("footer-fixed");
    };
  });

  return (
    <Transitions>
      <Helmet>
        <title>Majal | Sobre Nosotros</title>
        <meta
          name="description"
          content="Majal surge en Montevideo, el 25 de noviembre de 2022 como iniciativa de un grupo de amigos con ganas de juntarse a hacer música. Radicados en Sala Jackson, como lugar de ensayo, comenzó a componer sus primeros temas propios: “Epitafio”, “Ciudad” y “Ojos Pardos”. Majal presenta un estilo propio influenciado por los clásicos del rock uruguayo como Buitres, Trotsky Vengarán, La Trampa, entre otros, consolidando un estilo rock-punk ."
        />
        <link rel="canonical" href="/about-us" />
      </Helmet>
      <div id="mjl-container-about-us">
        <div className="mjl-about--header">
          <div className="mjl-about--header-hero">
            <div className="mjl-about--header-title">
              <img
                src="/majal-tentacles-transparent-black.png"
                alt="Majal Tentáculos"
              />
              <h1 className="majal-title">MAJAL</h1>
            </div>
            <div className="mjl-about--header-subtitle">
              <p>
                <span className="majal-title">Majal</span> surge en Montevideo,
                el 25 de noviembre de 2022 como iniciativa de un grupo de amigos
                con ganas de juntarse a hacer música. Radicados en Sala Jackson,
                como lugar de ensayo, comenzó a componer sus primeros temas
                propios: “Epitafio”, “Ciudad” y “Ojos Pardos”. Majal presenta un
                estilo propio influenciado por los clásicos del rock uruguayo
                como Buitres, Trotsky Vengarán, La Trampa, entre otros,
                consolidando un estilo rock-punk .
              </p>
            </div>
          </div>
        </div>
        <section className="mjl-about--description">
          <p>
            <span className="majal-title">Majal</span> surge en Montevideo, el
            25 de noviembre de 2022 como iniciativa de un grupo de amigos con
            ganas de juntarse a hacer música. Radicados en Sala Jackson, como
            lugar de ensayo, comenzó a componer sus primeros temas propios:
            “Epitafio”, “Ciudad” y “Ojos Pardos”. Majal presenta un estilo
            propio influenciado por los clásicos del rock uruguayo como Buitres,
            Trotsky Vengarán, La Trampa, entre otros, consolidando un estilo
            rock-punk .
          </p>
        </section>
        <section className="mjl-about--social">
          <h6 className="text-center pb-4">
            Seguínos en nuestras redes oficiales:
          </h6>
          <div className="social-media">
            <div className="icon-box">
              <a
                href="https://www.instagram.com/majal_banda/"
                target={"_blank"}
                rel="noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
            <div className="icon-box">
              <a
                href="https://twitter.com/majalbanda"
                target={"_blank"}
                rel="noreferrer"
              >
                <i className="fa-brands fa-x-twitter"></i>
              </a>
            </div>
            <div className="icon-box">
              <a
                href="https://open.spotify.com/artist/1AEigCi2awIw60QIdrMcjZ"
                target={"_blank"}
                rel="noreferrer"
              >
                <i className="fab fa-spotify"></i>
              </a>
            </div>
            <div className="icon-box">
              <a
                href="https://www.youtube.com/@MajalBanda"
                target={"_blank"}
                rel="noreferrer"
              >
                <i className="fab fa-youtube"></i>
              </a>
            </div>
            <div className="icon-box">
              <a
                href="https://music.apple.com/us/artist/majal/1683598625"
                target={"_blank"}
                rel="noreferrer"
              >
                <i className="fab fa-apple"></i>
              </a>
            </div>
          </div>
        </section>
        <section className="mjl-about--band-members">
          <h1 className="majal-title">Integrantes:</h1>
          {MEMBERS.map((member, index) => (
            <div className="card mb-3" key={index}>
              <div className="row g-0">
                <div className="col-md-4 d-flex align-items-center">
                  <img
                    src={member.imgUrl}
                    className="img-fluid rounded"
                    alt={member.name}
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h5 className="card-title majal-title">{member.name}</h5>
                    <p className="card-text majal-title">{member.instrument}</p>
                    <p
                      className="card-text mjl-description"
                      dangerouslySetInnerHTML={{ __html: member.description }}
                    ></p>
                    <div className="card-text social-media">
                      <div className="icon-box">
                        <a
                          href={member.instaUrl}
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>{" "}
                      </div>
                      <div className="icon-box">
                        <a
                          href={member.xUrl}
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          <i className="fa-brands fa-x-twitter"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>
      </div>
    </Transitions>
  );
};

export default AboutUs;
