import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Transitions from "../Transitions";

const Gallery = () => {
  return (
    <Transitions>
      <Helmet>
        <title>Majal | Galería</title>
        <meta
          name="description"
          content="Mira nuestra galería de fotos y conocé más sobre nuestra banda"
        />
        <link rel="canonical" href="/gallery" />
      </Helmet>
      <div id="gallery">
        <div className="card">
          <div className="content">
            <h2>MAJAL</h2>
            <span className="avatar">
              <img src="./assets/images/majal-gigs.jpg" alt="" />
            </span>
            <h3 className="majal-title">CONCIERTOS</h3>
            <Link to={"/gallery/gigs"} className="btn-primary">
              Visitar
            </Link>
          </div>
        </div>
        <div className="card">
          <div className="content">
            <span className="avatar">
              <img src="./assets/images/majal-practices.jpg" alt="" />
            </span>
            <h2>MAJAL</h2>
            <h3 className="majal-title">ENSAYOS</h3>
            <Link to={"/gallery/practices"} className="btn-primary">
              Visitar
            </Link>
          </div>
        </div>
        <div className="card">
          <div className="content">
            <span className="avatar">
              <img src="./assets/images/majal-studio.jpg" alt="" />
            </span>
            <h2>MAJAL</h2>
            <h3 className="majal-title">ESTUDIO</h3>
            <Link to={"/gallery/studio"} className="btn-primary">
              Visitar
            </Link>
          </div>
        </div>
      </div>
    </Transitions>
  );
};

export default Gallery;
