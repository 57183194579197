import React from "react";
import ReactDom from "react-dom";
import { Modal } from "react-bootstrap";

type HomeModalProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const HomeModalView = ({ showModal, setShowModal }: HomeModalProps) => {
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-music-preview"
      centered
    >
      <Modal.Body>
        <div className="card">
          <img
            src="./assets/images/majal-epitafio-cover.jpeg"
            alt="Majal - Epitafio"
            className="card-img"
          />
          <div className="card-body">
            <p className="card-text">ESCUCHAR AHORA</p>
            <div className="card-text social-media">
              <div className="icon-box">
                <a
                  href="https://open.spotify.com/album/48Dmo2IChtTWjE60R3kHJU?si=GGsWDSjYQjaxQ8JczT7gcQ"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <i className="fab fa-spotify"></i>
                </a>
              </div>
              <div className="icon-box">
                <a
                  href="https://www.youtube.com/watch?v=8p-uqBkh52c&list=PLgtp2rNsdyi5wKt_pWDv5U6_AN4GSStp3"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
              <div className="icon-box">
                <a
                  href="https://music.apple.com/us/artist/majal/1683598625"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <i className="fab fa-apple"></i>
                </a>
              </div>
            </div>
            <span
              className="modal-close-custom"
              onClick={() => setShowModal(false)}
            >
              cerrar
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const HomeModal = ({ showModal, setShowModal }: HomeModalProps) => {
  return (
    <React.Fragment>
      {
        // @ts-ignore
        // prettier-ignore
        ReactDom.createPortal(<HomeModalView showModal={showModal} setShowModal={setShowModal} />, document.getElementById("modal-view"))
      }
    </React.Fragment>
  );
};

export default HomeModal;
