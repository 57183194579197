import { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { updateMajalMedia } from "../redux/actions";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import { MajalStore } from "../types/types";

import { LinkContainer } from "react-router-bootstrap";

const NavBar = () => {
  const store = useSelector((state: MajalStore) => state?.mediaForMajal);
  const API_URL = process.env.REACT_APP_API_URL ?? "";
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(store).length <= 0) {
      fetch(API_URL)
        .then((response) => response.json())
        .then((result) => {
          if (result.gigs.length >= 1 && result.practices.length >= 1) {
            dispatch(updateMajalMedia(result));
          } else {
            dispatch(updateMajalMedia({}));
          }
        })
        .catch((e) => console.log("service not available"));
    }
    /* eslint-disable-next-line*/
  }, []);
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
        <Container>
          <LinkContainer to={"/"}>
            <Navbar.Brand>
              <img
                alt=""
                src="/logo192.png"
                width="30"
                height="30"
                className="d-inline-block align-top rounded-circle"
              />{" "}
              <span className="majal-title">MAJAL</span>
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              <LinkContainer to={"/music"}>
                <Nav.Link>Música</Nav.Link>
              </LinkContainer>
              <NavDropdown title="Galería" id="collasible-nav-dropdown">
                <LinkContainer to="/gallery/gigs">
                  <NavDropdown.Item>Conciertos</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/gallery/practices">
                  <NavDropdown.Item>Ensayos</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/gallery/studio">
                  <NavDropdown.Item>Estudio</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              <LinkContainer to={"/about-us"}>
                <Nav.Link>Nosotros</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
