import { MajalStore, ActionType } from "../types/types";

const initialState: MajalStore = {
  mediaForMajal: {},
  modalImage: "",
};

const rootReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case "UPDATE_MAJAL_MEDIA":
      return {
        ...state,
        mediaForMajal: action.payload,
      };
    case "UPDATE_MODAL_IMAGE":
      return {
        ...state,
        modalImage: action.payload,
      };
    default:
      return state;
  }
};

export default rootReducer;
