import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import ImageViewer from "../../components/ImageViewer";

import Transitions from "../Transitions";
import { ScaleLoader } from "react-spinners";

import { updateModalImage } from "../../redux/actions";

import { MajalStore, MajalImage } from "../../types/types";

const Practice = () => {
  // @ts-ignore
  const { practices } = useSelector(
    (state: MajalStore) => state?.mediaForMajal
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const footer = document.querySelector("#footer");
    footer?.classList.remove("footer-fixed");

    return function cleanup() {
      const footer = document.querySelector("#footer");
      footer?.classList.add("footer-fixed");
    };
  });

  const activeImg = (imgSrc: string) => {
    dispatch(updateModalImage(imgSrc));
  };

  return (
    <Transitions>
      <Helmet>
        <title>Majal | Galería - Ensayos</title>
        <meta
          name="description"
          content="Tené un acercamiento a nuestros ensayos con contenido exclusivo."
        />
        <link rel="canonical" href="/gallery/practices" />
      </Helmet>
      {practices ? (
        <div id="gallery-view">
          {practices.map((image: MajalImage) => (
            <div
              className="pics"
              key={image.asset_id}
              onClick={() => activeImg(image.secure_url)}
              data-toggle="modal"
              data-target="#imageModal"
            >
              <ImageViewer image={image} placeholder="practices" />
            </div>
          ))}
        </div>
      ) : (
        <span className="loading-spinner">
          <ScaleLoader color="#FFFFFF" />
        </span>
      )}
    </Transitions>
  );
};

export default Practice;
