import React from "react";
import ReactDom from "react-dom";

import { useSelector } from "react-redux";

import { MajalStore } from "../../types/types";

const ModalView = () => {
  const modalImage = useSelector((state: MajalStore) => state?.modalImage);

  return (
    <div
      className="modal fade"
      id="imageModal"
      tabIndex={-1}
      aria-labelledby="imageModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content ">
          <div className="modal-body">
            <div className="card bg-dark text-white">
              {modalImage && (
                <img src={modalImage} alt="" className="card-img" />
              )}
              <div className="card-img-overlay">
                <div className="modal-view-btns">
                  <button
                    type="button"
                    className="btn-primary"
                    data-dismiss="modal"
                  >
                    <i className="fas fa-times"></i>
                    <span>CERRAR</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Modal = () => {
  return (
    <React.Fragment>
      {
        // @ts-ignore
        ReactDom.createPortal(<ModalView />, document.getElementById("modal-view"))
      }
    </React.Fragment>
  );
};

export default Modal;
