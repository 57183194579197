import { LazyLoadImage } from "react-lazy-load-image-component";
import { MajalImage } from "../types/types";

import "react-lazy-load-image-component/src/effects/blur.css";

type ImageViewerProps = {
  image: MajalImage;
  placeholder: "studio" | "gigs" | "practices";
};

const ImageViewer = ({ image, placeholder = "studio" }: ImageViewerProps) => {
  return (
    <LazyLoadImage
      alt={image.asset_id}
      src={image.secure_url}
      width={"100%"}
      effect="blur"
      placeholderSrc={require(`../assets/images/majal-${placeholder}.jpg`)}
    />
  );
};

export default ImageViewer;
