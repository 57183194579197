import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";

import App from "./App";
import store from "./redux/store";

import { Gallery, Practice, Gigs, Studio, Music, AboutUs } from "./pages";

import { NavBar, Modal, Footer } from "./components";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/main.scss";

const ErrorPage = () => {
  useEffect(() => {
    window.location.pathname = "/";
  });
  return <h1>Loading...</h1>;
};

const routing = (
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <Router>
          <NavBar />
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/gallery/practices" element={<Practice />} />
            <Route path="/gallery/gigs" element={<Gigs />} />
            <Route path="/gallery/studio" element={<Studio />} />
            <Route path="/music" element={<Music />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Router>
      </HelmetProvider>
      <Footer />
      <Modal />
    </Provider>
  </React.StrictMode>
);
// @ts-ignore
ReactDOM.createRoot(document.getElementById("wrapper")).render(routing);
