import { Helmet } from "react-helmet-async";
import Transitions from "./Transitions";

function Music() {
  return (
    <Transitions customStyle="music-container">
      <Helmet>
        <title>Majal | Música</title>
        <meta
          name="description"
          content="Escucha nuestras canciones en tu plataforma favorita"
        />
        <link rel="canonical" href="/music" />
      </Helmet>
      <div id="music" className="container">
        <div className="card mb-3">
          <div className="row no-gutters">
            <div className="col-md-4">
              <img
                src="./assets/images/majal-epitafio-cover.jpeg"
                alt="Majal - Epitafio - EP"
              />
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h1 className="card-title majal-title">EPITAFIO</h1>
                <p className="card-text">
                  La noche despliega su manto, los grillos cantan en este
                  callejón que a veces se torna sin salida, tu corazón está
                  latiendo entre tanta gente… regálanos tu sonrisa.. nada más
                  hará falta en este momento que compartiremos entre muchos más
                  que dos…
                </p>
                <div className="card-text">
                  <ol>
                    <li>Epitafio</li>
                    <li>Brazos</li>
                    <li>Seducción</li>
                  </ol>
                </div>
                <div className="card-text social-media">
                  <div className="icon-box">
                    <a
                      href="https://open.spotify.com/album/48Dmo2IChtTWjE60R3kHJU?si=GGsWDSjYQjaxQ8JczT7gcQ"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <i className="fab fa-spotify"></i>
                    </a>
                  </div>
                  <div className="icon-box">
                    <a
                      href="https://www.youtube.com/watch?v=8p-uqBkh52c&list=PLgtp2rNsdyi5wKt_pWDv5U6_AN4GSStp3"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </div>
                  <div className="icon-box">
                    <a
                      href="https://music.apple.com/us/artist/majal/1683598625"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <i className="fab fa-apple"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transitions>
  );
}

export default Music;
