const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer id="footer" className="footer-fixed">
      <ul className="copyright">
        <li>
          <span className="uy-flag">
            <img
              src="https://flagsapi.com/UY/flat/24.png"
              title="Uruguay"
              alt="Uruguay"
            />
          </span>
        </li>
        <li>&copy; {currentYear} MAJAL | Todos los derechos reservados. </li>
      </ul>
      <span className="jaes-made-it">
        Desarrollador por{" "}
        <a
          href="https://www.jaesmadeit.com/"
          target={"_blank"}
          rel="noreferrer"
        >
          JAES Made It
        </a>
      </span>
    </footer>
  );
};

export default Footer;
